@import "variables";
// Benefits

.tooltip {
    font-weight: $tooltip-font-weight;
    .tooltip-inner {
        color: $tooltip-color-inner;
        font-weight: $font-weight-normal;
    }
}

@include media-breakpoint-up(md) {
    .tooltip {
        font-size: $tooltip-font-size-md;
    }

    .tooltip-inner {
        padding: $tooltip-padding-y-md $tooltip-padding-x-md;
    }
}

.benefits-container {

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
    }

    .h3 {
        color: $benefits-heading-color;

        @include media-breakpoint-down(md) {
            margin-top: 3rem;
            margin-left: 105px;
            max-width: 12.5rem;
        }

        @include media-breakpoint-up(lg) {
            flex-grow: 1;
            width: 25%;
            padding-left: 1rem;
        }
    }
}


@media (max-width: 359.98px) {
    .benefits-container {
        .h3 {
            margin-left: 90px;
        }
    }
}


.benefits-item {
    text-align: center;
    @include media-breakpoint-up(lg) {
        margin-bottom: 5.25rem;
        flex-grow: 1;
        width: 25%;
        padding: 0 1rem;

        &:first-child {
            margin-left: 25%
        }
    }


    .benefits-item-inner {
        display: block;
        max-width: 200px;
        margin: 0 auto;
    }

    .benefits-icon {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $icon-background-benefits;
        padding-bottom: 6px;
        //background: linear-gradient(-45deg, #047f88, #059FAA, #23d5ab, #53e3c1);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;

        @include media-breakpoint-up(sm) {
            width: 80px;
            height: 80px;
        }

        @include media-breakpoint-up(xl) {
            width: 120px;
            height: 120px;
        }

        &:hover {
            text-decoration: none;
        }

        .main-icon {
            font-size: 36px;

            @include media-breakpoint-up(sm) {
                font-size: 48px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 80px;
            }

            .svg-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            svg {
                fill: $icon-fill-benefits;
            }
        }

        .icon-info {
            position: absolute;
            bottom: 0;
            right: 6px;
        }
    }

    .benefits-title {
        margin-top: 0.375rem;

        @include media-breakpoint-down(xs) {
            font-size: 0.625rem;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 0.75rem;
        }
    }
}
