@import "variables";
.frame-layout-right.frame-type-list .frame-grid .frame-content {
    grid-column: 6 / span 6;
}

.frame-layout-right.frame-type-list .frame-grid .frame-header {
    grid-column: 3 / span 4;

    h2 {
        max-width: 80%;
    }
}

.tx-wvier-joblist,
.tx-wvier-emp-jobs {
    .joblist-header,
    .jobfilter {
        width: 83.33%;
        margin-left: 16.67%;
    }

    .jobfilter {
        margin-bottom: 3rem;

        .filter-container {

            .form-group {
                margin-bottom: 1.75rem;
            }

            select {
                cursor: pointer;
                width: 100%;
                border: 0;
                background: url('../../Icons/SVG/down-arrow.svg')right 0.8rem center/13px 13px no-repeat $filter-field-background;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                appearance: none;
                padding: 0.5rem 1rem;
            }

            /*** iPhone and iOS Form Input Zoom Fixes ***/
            /* Fix Input Zoom on devices older than iPhone 5: */
            @media screen and (device-aspect-ratio: 2/3) {
                select{
                    font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
            @media screen and (device-aspect-ratio: 40/71) {
                select{ font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
            @media screen and (device-aspect-ratio: 375/667) {
                select{ font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
            @media screen and (device-aspect-ratio: 9/16) {
                select{ font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            @media screen and (device-aspect-ratio: 0) {
                select{ font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            select::-ms-expand {
                display: none;
            }

            button.icon-button {
                margin-left: 1.2rem;
                color: $filter-icon-color;
                border: none;
                background: none;
                font-size: 1.25rem;

                &:hover,
                &:active {
                    outline: none;
                    color: $filter-icon-color-hover;
                }
            }
        }
    }

    .accordion-header-joblist {
        background-color: $primary-color;
        color: $secondary-color;
        padding: 1.15rem 2.75rem 1.25rem;
        font-weight: 500;

        .job-header {
            display: inline-block;
            width: 69.4%;
        }

        .location-header {
            display: inline-block;
            width: 30.6%;
        }
    }

    #jobListAccordion {
        &.loading-icon {
            position: relative;
            &:after {
                content: "";
                background-image: url('../../Icons/loading.gif');
                width: 3rem;
                height: 3rem;
                position: absolute;
                z-index: 111;
                top: 50%;
                left: 50%;
                display: block;
            }
        }

        .nojobs {
            background-color: white;
            padding: 2rem;
            text-align: center;

            .nojobs-text {
                padding-bottom: 10px;
            }
        }
        .card {
            border: 0;
            margin-top: 2px;
            margin-bottom: 2px;

            .card-header {
                background-color: $secondary-color;
                padding-bottom: 0;
                padding-top: 0;
                border: 0;
                transition: background-color 0.5s ease-in-out;

                &.active-acc {
                    background-color: $secondary-color;

                    .btn {
                        color: $white;

                        .job-title {
                            .svg-icon {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }

                .btn {
                    text-transform: none;
                    width: 100%;
                    text-align: left;
                    padding-top: 1.25rem;
                    padding-bottom: 1.5rem;
                    transition: color 0.5s ease-in-out;

                    display: flex;
                    align-items: center;

                    .job-title {
                        display: flex;
                        align-items: center;
                        width: 69.4%;
                        color: $white;

                        .svg-icon {
                            transform: rotate(90deg);
                            margin-right: 1.2rem;
                            transition: transform 0.3s ease;
                        }
                    }

                    .locations {
                        display: inline-block;
                        width: 30.6%;
                        color: white;
                    }
                }
            }

            .card-body {
                padding: 0.75rem 0.5rem 0.75rem 0.5rem;

                .btn {
                    text-transform: uppercase;
                    margin-right: 1.1rem;
                    margin-bottom: 5px;
                }

                ul {
                    padding-left: 0;

                    li {
                        list-style: none;
                        position: relative;
                        padding-left: 20px;

                        &:before {
                            content: '-';
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                    }
                }

                h3 {
                    font-size: $font-size-lg;
                }
            }



            .btn {
                text-transform: none;
            }
        }
    }

    #applicationForm {
        margin-top: 1.2rem;

        #showForm {
            background-color: $primary-color;
            color: $secondary-color;
            padding: 1rem 1rem 1.25rem 1.75rem;
            font-weight: 500;
            cursor: pointer;

            .svg-icon {
                transform: rotate(90deg);
                transition: transform 0.3s ease;
                margin-right: 1.75rem;
            }

            &.open {
                .svg-icon {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    .tx-powermail {
        height: 0;
        opacity: 0;
        display: none;
        background-color: $white;
        transition: all 0.5s ease;

        .powermail_form {
            padding: 0.1rem 0 1rem 0;
        }

        #videoUploadAccordion {
            margin-top: 2rem;

            .card {
                border: none;
                background-color: $form-field-background;

                .card-header {
                    border: none;
                    background-color: $video-accordion-background;
                    color: $video-accordion-color;

                    .btn {
                        color: inherit;
                        width: 100%;
                        border-radius: 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .right-part {
                            svg {
                                transform: rotate(-90deg);
                                transition: transform 0.3s ease;
                            }
                        }

                        .left-part {
                            svg {
                                margin-bottom: 0.1rem;
                            }
                        }

                        &:focus {
                            box-shadow: none;
                        }

                        &.collapsed {
                            .right-part {
                                svg {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }

                .card-body {
                    .row {
                        padding: 15px;

                        .powermail_fieldwrap_type_file {
                            label.powermail_label {
                                top: 2px;
                            }
                            .custom-upload-field,
                            input {
                                margin: 0;
                            }
                        }

                        .powermail_fieldwrap_type_text {
                            &:not(.powermail_fieldwrap_introduction) {
                                color: $video-question-color;
                                font-weight: $video-question-font-weight;
                            }

                        }
                    }
                }
            }
        }

        label {
            a {
                text-decoration: underline;
            }
        }

        .powermail_fieldset:not(nolabel) {
            margin-top: 1rem;

            .h3 {
                font-weight: 400;
                margin-bottom: 0;
            }
        }

        .powermail_field,
        .powermail_fieldwrap_type_file {
            margin-bottom: 1rem;
        }

        .powermail_fieldwrap_type_file {
            &.powermail_fieldwrap_weiteredokumente {
                label.powermail_label {
                    color: $white;
                    transition: all 0.3s ease;

                    &.start-input {
                        color: $dark;
                    }
                }
            }

            &.button {
                label.powermail_label {
                    color: $white;
                    transition: all 0.3s ease;

                    &.start-input {
                        color: $dark;
                    }
                }
            }
        }

        .powermail_fieldwrap {
            position: relative;

            .powermail_label {
                position: absolute;
                z-index: 1;
                top: 1.75rem;
                left: 30px;
                transition: all 0.3s ease;
                font-size: 0.7rem;
                cursor: pointer;

                &.start-input {
                    top: 0.2rem;
                    left: 15px;
                    cursor: unset;
                }
            }

            /*** iPhone and iOS Form Input Zoom Fixes ***/
            /* Fix Input Zoom on devices older than iPhone 5: */
            @media screen and (device-aspect-ratio: 2/3) {
                select, textarea, input{
                    font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
            @media screen and (device-aspect-ratio: 40/71) {
                select, textarea, input{
                    font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
            @media screen and (device-aspect-ratio: 375/667) {
                select, textarea, input{
                    font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
            @media screen and (device-aspect-ratio: 9/16) {
                select, textarea, input{
                    font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            @media screen and (device-aspect-ratio: 0) {
                select, textarea, input{
                    font-size: 16px;

                    &:focus {
                        font-size: 16px;
                    }
                }
            }

            input,
            select,
            textarea,
            .custom-upload-field{
                margin-top: 1.2rem;
                padding: 0.5375rem 0.75rem;
                font-size: 0.7rem;
                cursor: pointer;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    padding-bottom: 0.75rem;
                }

                &.powermail_submit {
                    margin-top: 0.9rem;
                    padding: 0.5rem 0.75rem;;
                }
            }

            select {
                background: url('../../Icons/SVG/down-arrow.svg')right 0.8rem center/13px 13px no-repeat $form-field-background;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                appearance: none;
                position: relative;
            }

            select::-ms-expand {
                display: none;
            }


            .parsley-errors-list {
                padding: 0;

                &.filled {
                    li {
                        list-style: none;
                        color: $red;
                        font-size: 0.875rem;
                    }
                }
            }

            .checkbox.parsley-error + .powermail_field_error_container  {
                margin-left: 1rem;
            }
        }

        #videoUploadAccordion {
            .btn {
                .left-part,
                .right-part  {
                    font-size: 0.7rem;
                }
            }

            .powermail_field {
                font-size: 0.7rem;
            }
        }

        .custom-upload-field {
            color: $form-field-background;
            transition: all 0.3s ease;
            width: calc(100% - 30px);

            &.show-me {
                color: $dark;
            }

            &.primary {
                color: $secondary-color;
                background-color: $secondary-color;

                &.show-me {
                    color: $white;
                }
            }

            &.btn {
                color: $primary-color;
                background-color: $primary-color;
                width: auto;

                &.show-me {
                    color: $white;
                }
            }


        }

        .upload-icons {
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
        }

        .filelist {
            li {
                list-style: none;
                font-size: 0.7rem;

                .svg-icon {
                    margin-left: 7px;
                }
            }
        }

        .powermail_fieldwrap_bewerbungsartbewerbungfuer {
            .powermail_field {
                opacity: 0;
                height: 1px;
                width: 1px;
                margin: 0;
                overflow: hidden;

                .powermail_input {
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .powermail_select,
        .powermail_input,
        .powermail_date,
        .powermail_textarea,
        .custom-upload-field {
            border: none;
            background-color: $form-field-background;
            width: 100%;
            padding: 0.9375rem 1.375rem;

            &::placeholder {
                color: $body-color;
            }
        }

        .file-upload-hidden {
            margin-bottom: 0;

            input {
                opacity: 0;
            }

            .parsley-errors-list.filled {
                margin-top: 30px;
            }
        }

        .form-heading {
            padding-left: 15px;
            padding-right: 15px;

            h3 {
                margin-bottom: 0;
            }
        }

        .custom-upload-field {
            position: absolute;
            width: calc(100% - 30px);
            padding: 0.9375rem 1.375rem;
        }

        .checkbox {
            padding-left: 2rem;
            position: relative;

            label {
                padding-left: 1rem;
                font-size: 0.7rem;
            }

            .powermail_checkbox {
                position: absolute;
                opacity: 0;
                cursor: pointer;

                &:checked ~ .powermail_custom_checkbox {
                    transform: rotate(0deg) scale(1);
                    opacity: 1;

                    &:after {
                        transform: rotate(45deg) scale(1);
                        opacity: 1;
                        left: 9px;
                        top: 4px;
                        width: 6px;
                        height: 12px;
                        border: solid $body-color;
                        border-width: 0 2px 2px 0;
                        background-color: transparent;
                    }
                }
            }

            .powermail_custom_checkbox {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 15px;
                height: 24px;
                width: 24px;
                background-color: $form-field-background;

                &:after {
                    position: absolute;
                    content: "";
                    left: 12px;
                    top: 12px;
                    height: 0;
                    width: 0;
                    border: solid $form-field-background;
                    border-width: 0 3px 3px 0;
                    transform: rotate(0deg) scale(0);
                    opacity: 1;
                }
            }
        }

        .powermail_fieldwrap_type_submit {
            .powermail_submit {
                .spinner-border {
                    position: relative;
                    top: -3px;
                    display: none;
                }
                &.loading {
                    background-color: rgba($primary-color, 0.8);
                    .spinner-border {
                        display: inline-block;
                    }
                    .submit-text {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
}

#formSuccessMessage {
    background-color: $white;
    margin-top: 0.8rem;
    padding: 2.5rem;

    .frame-grid {
        margin-top: 0;

        .frame-background {
            grid-column: 1/span 12;
            background-color: $white;
        }

        .frame-content {
            grid-column: 1/span 12;
            margin-left: 0;
            background-color: $white;

            .text-container {
                background-color: $white;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .frame-layout-right.frame-type-list .frame-grid .frame-content {
        margin-left: 75px;
    }

    .tx-wvier-joblist,
    .tx-wvier-emp-jobs  {
        .jobfilter {
            .filter-container {
                select {
                    background: url('../../Icons/SVG/down-arrow.svg')right 0.8rem center/15px 15px no-repeat $filter-field-background;
                }
            }
        }

        #jobListAccordion {
            .card {
                .card-body {
                    padding: 1.4rem 2rem 1.4rem 1.5rem;
                }
            }
        }

        .tx-powermail {
            .powermail_form {
                padding: 0.1rem 0.5rem 1rem 0.5rem;
            }

            .powermail_fieldset:not(nolabel) {
                margin-top: 2.5rem;
            }

            .powermail_fieldwrap {
                .powermail_label {
                    top: 2.1rem;
                    left: 30px;

                    &.start-input {
                        top: 0;
                        left: 15px;
                    }
                }

                input,
                select,
                textarea,
                .custom-upload-field{
                    margin-top: 1.5rem;

                    &.powermail_submit {
                        margin-top: 0.75rem;
                    }
                }

                select {
                    background: url('../../Icons/SVG/down-arrow.svg')right 1.25rem center/15px 15px no-repeat $form-field-background;
                }
            }

            .upload-icons {
                top: 1.75rem;
                right: 2.5rem;
            }


            .powermail_select,
            .powermail_input,
            .powermail_date,
            .powermail_textarea,
            .custom-upload-field {
                padding: 0.6375rem 0.375rem;
            }

            .file-upload-hidden {
                .parsley-errors-list.filled {
                    margin-top: 30px;
                }
            }

            .custom-upload-field {
                padding: 0.6375rem 0.375rem;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .tx-wvier-joblist,
    .tx-wvier-emp-jobs {

        .jobfilter {
            .filter-container {
                .buttons {
                    font-size: 0.85rem;
                }
                select {
                    background: url('../../Icons/SVG/down-arrow.svg')right 1.25rem center/18px 18px no-repeat $filter-field-background;
                }
            }
        }

        #jobListAccordion {
            .card {
                .card-body {
                    padding: 2.4rem 5rem 2.4rem 4.5rem;
                }
            }
        }

        #applicationForm {
            #showForm {
                padding-left: 2.75rem;
            }
        }

        .tx-powermail {
            #videoUploadAccordion {
                .btn {
                    .left-part,
                    .right-part  {
                        font-size: 0.9rem;
                    }
                }

                .powermail_field {
                    font-size: 0.9rem;
                }
            }

            .filelist {
                padding-top: 0.5rem;
                padding-left: 0.5rem;

                li {
                    font-size: 0.9rem;
                }
            }

            .upload-icons {
                top: 1.7rem;
                font-size: 1rem;
            }

            .powermail_form {
                padding: 1.8rem 1.25rem 2rem 1.5rem;
            }

            .powermail_fieldwrap {
                select {
                    background: url('../../Icons/SVG/down-arrow.svg')right 1.25rem center/18px 18px no-repeat $form-field-background;
                }

                .powermail_label {
                    font-size: 0.9rem;
                    top: 1.9rem;
                }

                input.powermail_submit {
                    padding: 0.75rem 1.5rem;
                }
            }

            .powermail_select,
            .powermail_input,
            .powermail_date,
            .powermail_textarea,
            .custom-upload-field {
                padding: 0.9375rem 1.375rem;
                font-size: 0.9rem;
            }

            .custom-upload-field {
                padding: 0.9375rem 1.375rem;
            }
            .checkbox {
                label {
                    font-size: 0.9rem;
                }
            }
        }
    }
}


@include media-breakpoint-up(lg) {
    .frame-layout-right.frame-type-list .frame-grid .frame-content {
        grid-column: 5 / span 7;
    }

    .tx-wvier-joblist,
    .tx-wvier-emp-jobs {
        #jobListAccordion {
            .card {
                .card-body {
                    padding: 2.4rem 4rem 2.4rem 3.5rem;
                }
            }
        }

        .tx-powermail {
            .powermail_form {
                padding: 1.8rem 1.25rem 2rem 1.5rem;
            }

            .upload-icons {
                top: 2.3rem;
            }

            #videoUploadAccordion {
                .card {
                    .card-body {
                        .row {
                            .powermail_fieldwrap_type_file {
                                label.powermail_label {
                                    left: 2.75rem;
                                    top: 4px;
                                }
                            }
                        }
                    }
                }

                .btn {
                    .left-part,
                    .right-part {
                        font-size: 0.9rem;
                    }
                }

                .powermail_field {
                    font-size: 0.9rem;
                }
            }

            .powermail_fieldwrap {
                .powermail_label {
                    top: 2rem;
                    font-size: 0.9rem;
                }

                input,
                select,
                textarea,
                .custom-upload-field{
                    font-size: 0.9rem;
                }
            }

            .powermail_select,
            .powermail_input,
            .powermail_date,
            .powermail_textarea,
            .custom-upload-field {
                padding: 0.9375rem 1.375rem;
            }

            .custom-upload-field {
                padding: 0.9375rem 1.375rem;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .frame-layout-right.frame-type-list .frame-grid .frame-content {
        grid-column: 5 / span 6;
    }

    .frame-layout-right.frame-type-list .frame-grid .frame-header {
        grid-column: 3 / span 4;
    }

    .tx-wvier-joblist,
    .tx-wvier-emp-jobs {
        #jobListAccordion {
            .card {
                .card-body {
                    padding: 2.4rem 5rem 2.4rem 4.5rem;

                    .btn {
                        margin-right: 1.1rem;
                    }

                    ul {
                        padding-left: 0;

                        li {
                            padding-left: 20px;

                            &:before {
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        .tx-powermail {
            #videoUploadAccordion {
                .card {
                    .card-body {
                        .row {
                            .powermail_fieldwrap_type_file {
                                label.powermail_label {
                                    left: 2.75rem;
                                    top: 4px;
                                }
                            }
                        }
                    }
                }
            }

            .powermail_fieldwrap {
                .powermail_label {
                    top: 2.5rem;
                    font-size: 0.9rem;
                }

                &.button {
                    .powermail_label {
                        top: 2.2rem;
                    }
                }

                input,
                select,
                textarea,
                .custom-upload-field{
                    margin-top: 2rem;
                    font-size: 0.9rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .tx-wvier-joblist,
    .tx-wvier-emp-jobs {
        .jobfilter {
            .filter-container {
                .buttons {
                    font-size: 1.25rem;
                }
            }
        }

        .tx-powermail {
            #videoUploadAccordion {
                .card {
                    .card-body {
                        .row {
                            .powermail_fieldwrap_type_file {
                                label.powermail_label {
                                    left: 3rem;
                                    top: 2px;
                                }
                            }
                        }
                    }
                }
                .btn {
                    .left-part,
                    .right-part {
                        font-size: 1.25rem;
                    }
                }

                .powermail_field {
                    font-size: 1.25rem;
                }
            }

            .checkbox {
                label {
                    font-size: 1.25rem;
                }
            }

            .filelist {
                li {
                    font-size: 1.25rem;
                }
            }

            .upload-icons {
                top: 2.3rem;
                font-size: 1.25rem;
            }
            .powermail_fieldwrap {
                .powermail_label {
                    font-size: 1.25rem;
                }

                input,
                select,
                textarea,
                .custom-upload-field{
                    font-size: 1.25rem;
                }
            }
        }
    }
}
@media screen and (max-width: 1399px) {
    .tx-wvier-joblist,
    .tx-wvier-emp-jobs {
        .tx-powermail {
            #videoUploadAccordion {
                .card {
                    .card-header {
                        padding: 0.2rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .tx-wvier-joblist,
    .tx-wvier-emp-jobs {
        .tx-powermail {
            #videoUploadAccordion {
                .card {
                    .card-body {
                        .row {
                            .powermail_fieldwrap_type_file {
                                label.powermail_label {
                                    top: 0.4rem;
                                    left: 2.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs){
    .tx-wvier-joblist,
    .tx-wvier-emp-jobs {
        .jobfilter {
            margin-bottom: 3rem;

            .filter-container {
                select {
                    background: url('../../Icons/SVG/down-arrow.svg')right 0.8rem center/13px 13px no-repeat $filter-field-background;
                }

                button.icon-button {
                    margin-left: 0.5rem;
                }

                .buttons {
                    font-size: 0.7rem;
                }
            }
        }

        #jobListAccordion {
            .card {
                .card-header {
                    padding-bottom: 0;
                    padding-top: 0;
                    .btn {
                        width: 100%;
                        flex-direction: column;
                        padding-left: 0;
                        padding-right: 0;

                        .job-title {
                            width: 100%;

                            .svg-icon {
                                width: 2rem;
                            }
                            span {
                                width: calc(100% - 2rem);
                            }
                        }

                        .locations {
                            width: calc(100% - 2rem);
                            padding-left: 2.1rem;
                            padding-top: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 575.99px) {
    .tx-wvier-joblist,
    .tx-wvier-emp-jobs {
        .jobfilter,
        .joblist-header {
            width: 100%;
            margin-left: 0;
        }
    }
}
