@import "variables";

@import "../../Contrib/OwlCarousel/owl.carousel.css";

.owl-carousel  {
    &.statements-carousel {
        .owl-item {
            img {
                width: auto;
            }
        }
    }

    &.media-carousel {
        .owl-item {
            aspect-ratio: 16/9;
        }
    }

    figure {
        margin: 0;
    }

    .owl-nav {
        button.owl-prev,
        button.owl-next {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 2rem;

            &:focus {
                outline: 1px dotted $dark;
            }
        }

        .nav-dots {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            text-align: center;

            span {
                position: relative;
                display: inline-block;
                border-radius: 50%;
                margin: 0 2px;
                background-color: black;
            }
        }

        button.owl-prev {
            left: 0;
            background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0) 100%);

            .nav-dots {

                span:nth-child(1) {
                    width: 3px;
                    height: 3px;
                    top: -2px;
                }

                span:nth-child(2) {
                    width: 5px;
                    height: 5px;
                    top: -1px;
                }

                span:nth-child(3) {
                    width: 7px;
                    height: 7px;
                }
            }
        }

        button.owl-next {
            right: 0;
            background: linear-gradient(to left, rgba($white, 1) 0%, rgba($white, 0) 100%);

            .nav-dots {

                span:nth-child(1) {
                    width: 7px;
                    height: 7px;
                }

                span:nth-child(2) {
                    width: 5px;
                    height: 5px;
                    top: -1px;
                }

                span:nth-child(3) {
                    width: 3px;
                    height: 3px;
                    top: -2px;
                }

            }
        }
    }
}


.owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
        display: inline-block;
        margin: 4px 6px 0;

        @include media-breakpoint-up(md) {
            margin: 4px 8px 0;
        }

        span {
            width: 8px;
            height: 8px;
            background: transparent;
            display: block;
            -webkit-backface-visibility: visible;
            transition: opacity 200ms ease;
            border-radius: 50%;
            border: 1px solid $dark;

            @include media-breakpoint-up(md) {
                width: 10px;
                height: 10px;
            }
        }

        &.active,
        &:hover {
            span {
                background: $dark;
            }
        }

        &:focus {
            outline: none;
        }
    }
}
