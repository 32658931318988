@import "variables";

// Statements
.statements-container {
    @include media-breakpoint-up(lg) {
        margin: -1.125rem auto 0 0;
        max-width: 80%;
    }

    @include media-breakpoint-up(xl) {
         max-width: 66.666%;
    }
}

.owl-carousel {
    .statements-item {
        display: none;
    }

    &.owl-loaded {
        .statements-item {
            display: flex;
        }
    }
}

.statements-item {
    text-align: center;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
        padding-top: 2.125rem;
    }

    .statements-item-text {
        padding-left: 24px;
    }

    .statements-item-quote {
        position: relative;
        font-size: $quote-font-size;
        line-height: $quote-line-height;

        @include media-breakpoint-up(md) {
            font-size: $quote-font-size-md;
            line-height: $quote-line-height-md;
        }

        .svg-icon {
            color: $quote-mark-color;
            font-size: $quote-mark-font-size;
            z-index: 1;
            margin-top: -1em;
            margin-right: -0.5em;

            @include media-breakpoint-up(md) {
                font-size: $quote-mark-font-size-md;
            }
        }
    }

    .statements-item-author {
        font-size: $statement-author-font-size;
        font-style: italic;

        @include media-breakpoint-up(md) {
            font-size: $statement-author-font-size-md;
        }
    }

    .statements-item-image {
        margin-top: -0.375rem;
        margin-right: -22px;

        @include media-breakpoint-up(md) {
            margin-top: -2.125rem;
        }
    }
}

.statements-carousel {
    .owl-dots {
        margin-left: 36px;
        @include media-breakpoint-up(md) {
            margin-left: 158px;
        }
    }
}