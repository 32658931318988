// Gallery

.gallery-carousel {
    .owl-dots {
        margin-top: 0.5rem;
    }

    .gallery-item {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        background-color: $light;

        &::before {
            display: block;
            content: "";
            padding-top: 75%;
        }

        .image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

