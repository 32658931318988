@import "variables";

.process-container {
    @include media-breakpoint-down(sm) {
        margin: 0 auto;
        padding-bottom: 1rem;
        width: 200px;
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.process-steps {
    position: relative;

    @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
        padding: 2rem 0;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        width: 100%;
        margin-right: 1rem;
        padding: 0 1rem;
        justify-content: space-around;
    }

    .process-arrow {
        display: block;
        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @include media-breakpoint-down(sm) {
            top: 0;
            bottom: 0;
            width: 22px;
            left: 30px;
            transform: translateX(-50%);
            background-image: url("../../Images/arrow_long_down.svg");
        }

        @include media-breakpoint-up(md) {
            top: 30px;
            transform: translateY(-50%);
            left: 5rem;
            right: 0;
            height: 22px;
            background-image: url("../../Images/arrow_long.svg");
        }

        @include media-breakpoint-up(xl) {
            top: 55px;
        }
    }
}

.process-item {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
        & + .process-item {
            margin-top: 1.25rem;
        }
    }

    @include media-breakpoint-up(md) {
        flex-direction: column;
        flex: 0 1 100%;
    }

    .process-item-icon {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $primary-color;
        //background: linear-gradient(-45deg, #047f88, #059FAA, #23d5ab, #53e3c1);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;

        @include media-breakpoint-down(sm) {
            margin-right: 2rem;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 1.25rem;
        }

        @include media-breakpoint-up(xl) {
            width: 110px;
            height: 110px;
        }

        .svg-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 38px;
            fill: $secondary-color;

            @include media-breakpoint-up(xl) {
                font-size: 64px;
            }
        }
    }

    .process-item-title {
        @include media-breakpoint-up(md) {
            text-align: center;
        }
    }

    &.final {
        @include media-breakpoint-up(md) {
            flex: 0 0 auto;
        }

        .process-item-icon {
            .svg-icon {
                left: 16px;
                top: 12px;
                transform: none;

                @include media-breakpoint-up(xl) {
                    left: 29px;
                    top: 23px;
                }

            }
        }
    }
}

.frame-type-wvier_process{
    &.animation-item {
        .frame-content {
            .process-arrow {
                bottom: 1000px;
                transition: all 1s ease-in-out;

                @include media-breakpoint-up(md) {
                    right: 1000px;
                }
            }

            .process-item-icon {
                width: 0;
                height: 0;
                opacity: 0;
                transition: all 1s ease-in-out;

                .svg-icon {
                    opacity: 0;
                    transition: all 0.5s ease-in-out;
                }
            }

            .process-item-title {
                opacity: 0;
                transition: all 0.5s ease-in-out;
            }
        }

        &.animated {
            .frame-content{
                .process-arrow {
                    bottom: 0;

                    @include media-breakpoint-up(md) {
                        right: 0;
                    }
                }

                .process-item-icon {
                    height: 60px;
                    width: 60px;
                    opacity: 1;

                    @include media-breakpoint-up(xl) {
                        width: 110px;
                        height: 110px;
                    }

                    .svg-icon {
                        opacity: 1;
                    }
                }

                .process-item-title {
                    opacity: 1;
                }

                $objects: 6;
                $delay: 0.2;
                @for $i from 1 through $objects {
                    .process-item:nth-child(#{$i}) {
                        .process-item-icon {
                            transition-delay: $delay + s;
                        }

                        .process-item-title {
                            transition-delay: $delay + 0.2 + s;
                        }
                    }
                    $delay: $delay + 0.2;
                }

                .process-item.final {
                    .process-item-icon {
                        transition-delay: $delay + s;
                    }
                }
            }
        }

    }
}
