@import "variables";

.jobfilter {
    display: none;
}

.jobs-h4 {
    text-align: center;
    margin-bottom: 25px;
    font-weight: $jobs-heading-font-weight;

    @include media-breakpoint-up(md) {

    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
        text-align: left;
    }

}

.jobs-container {
    display: flex;
    flex-wrap: nowrap;
    border: 0;
    justify-content: center;



    .owl-stage-outer {
        padding-top: 9px;
        padding-bottom: 9px;

        .owl-stage {
            .owl-item {
                height: 27vw;

               .jobs-item {
                   position: absolute;
                   left: 50%;
                   top: 0;
                   width: 27vw;
                   transform: translate(-50%);
               }
            }

            @media screen and (min-width: 367px){
                .owl-item {
                    height: 30vw;

                    .jobs-item {
                        width: 30vw;
                    }
                }
            }

            @media screen and (min-width: 420px){
                .owl-item {
                    height: 32vw;

                    .jobs-item {
                        width: 32vw;
                    }
                }
            }

            @include media-breakpoint-up(sm) {
                .owl-item {
                    height: 19vw;

                    .jobs-item {
                        width: 19vw;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                .owl-item {
                    height: 20vw;

                    .jobs-item {
                        width: 20vw;
                    }
                }
            }
        }
    }

    .jobs-item {
        border: none;
        padding: 0.5rem 0.5rem;
        background: none;

        .jobs-item-background {
            height: 100%;

            .background-color {
                transition: transform 0.5s;
                transform: scale(1);
            }

            &:before {
                background-color: $primary-color;
            }
        }

        .jobs-title {
            transition: opacity 0.5s 0.5s;
            opacity: 1;
        }

        .jobs-slogan{
            transition: opacity 0.5s 0s;
            opacity: 0;
        }

        &:focus {
            outline: none;
        }

        &.active {
            background-color: transparent;
            color: inherit;

            .jobs-slogan {
                transition: opacity 0.5s 0.5s;
                opacity: 1;
            }

            .jobs-title{
                transition: opacity 0.5s 0s;
                opacity: 0;
            }

            .jobs-item-image {
                &:before {
                    transform: scale(1.2);
                }
                img {
                    transform: scale(1.2);
                }
            }
        }
    }

    .jobs-item-image {
        position: relative;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $jobs-picture-overlay-color;
            border-radius: 10000px;
            z-index: 5;
            transition: transform 0.5s;
            transform: scale(1);
            left: 0;
        }

        img {
            width: 100%;
            height: auto;
            transition: transform 0.5s;
            transform: scale(1);
        }

        .jobs-title,
        .jobs-slogan {
            position: absolute;
            z-index: 10;
            color: $secondary-color;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: $jobs-slogan-font-size;
            font-weight: $jobs-slogan-font-weight;
            text-align: center;
            line-height: 1.2;
        }

        @media screen and (min-width: 367px){
            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-xxs;
            }
        }

        @media screen and (min-width: 420px){
            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-xs;
            }
        }

        @include media-breakpoint-up(sm) {
            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-sm;
            }
        }

        @media screen and (min-width: 670px){
            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-sm-md;
            }
        }

        @include media-breakpoint-up(md) {
            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-md;
                }
        }

        @include media-breakpoint-up(lg){
            .jobbereiche-bg {
                height: 180px;
                width: 180px;
            }

            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-lg;
            }
        }


        @media screen and (min-width: 1100px) {
            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-xl;
            }
        }

        @media screen and (min-width: 1200px){
            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-xxl;
            }
        }

        @media screen and (min-width: 1460px){
            .jobs-title,
            .jobs-slogan {
                font-size: $jobs-slogan-font-size-xxxl;

            }
        }

        .jobs-slogan {
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 1460px) {
    .jobs-container {
        margin-left: -40px;
        margin-right: -40px;
    }
}

.jobs-content {
    background-color: $jobs-content-background-color;
    margin-left: 1rem;
    margin-right: 1rem;

    p {
        color: $jobs-content-color;
    }

    .jobs-text {
        padding: 3.5rem 1.5rem 1.8rem;
        margin-top: -2.8rem;

        h3 {
            text-align: center;
            color: $jobs-content-color;
        }

        p {
            color: $jobs-content-color;
        }
    }
}

@include media-breakpoint-up(md) {
    .jobs-content {
        margin-left: 3.7rem;
        margin-right: 3.7rem;

        .jobs-text {
            padding: 7rem 7rem 3.8rem;
            margin-top: -5.8rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .jobs-content {
        .jobs-text {
            margin-top: -3.8rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .jobs-container {
        .jobs-item {
            padding: 0.5rem 1rem;
        }
    }
}

.jobs-carousel.owl-carousel {
    .owl-nav {
        button.owl-next {
            background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 58%);
        }

        button.owl-prev {
            background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 58%);
        }
    }
}

.job-badge {
    border: 2px solid #595959;
    border-radius: 12px;
    font-weight: bold;
    color: #595959;
    font-size: 14px;
    margin-bottom: 10px;
}
