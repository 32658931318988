@import "variables";

// Textmedia
.frame-type-textmedia {

    .frame-content {
        margin: 0 15px;
        padding: 1.875rem;

        &.color-text-media {
            background-color: $background-text-media;
            color: $color-text-media;

            h3 {
                color: $color-heading-text-media;
            }
        }

        @include media-breakpoint-up(lg) {
            padding-top: 2.625rem;
            padding-right: 8rem;
            padding-bottom: 2.625rem;
            padding-left: 3.5rem;
            margin: 0;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 3.875rem;
            padding-right: 11rem;
            padding-bottom: 3.875rem;
            padding-left: 6.25rem;
            margin: 0;
        }
    }

    .frame-image {
        margin: 0 15px;

        .img-fluid {
            display: block;
            margin: 0 auto;
        }

        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }

    .frame-lightbox {
        background-color: $background-text-media;

        @include media-breakpoint-between(sm, md) {
            background: linear-gradient(to bottom, rgba($background-text-media, 1) 0, rgba($background-text-media, 1) 75%, rgba($background-text-media, 0) 75%, rgba($background-text-media, 0) 100%);
        }

        @include media-breakpoint-down(md) {
            margin: 0 15px 20px;
        }
    }

    .mfp-video-link,
    .mfp-youtube-link {
        position: relative;
        display: block;
        color: $video-link-color;
        font-size: $video-link-size-sm;

        @include media-breakpoint-down(sm) {
            font-size: $video-link-size-xs;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $fourth-transparent-35;
            background: linear-gradient(0deg, $fourth-transparent-0 0%, $fourth-transparent-35 100%);
        }

        .img-fluid {
            min-width: 100%;
        }

        .svg-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media (min-width: 1680px) {
    .frame-type-textmedia {
        .color-text-media {
            padding-right: 12.5rem;
        }
    }
}

.frame-type-textpic {
    .textpic-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .textpic-text {
        line-height: 1.15;
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
            width: 100%;
            margin-top: 120px;
        }
    }

    @include media-breakpoint-up(lg) {
        .textpic-image {
            margin-top: 120px;
        }
    }
}

@media (min-width: 420px) {
    .frame-type-textpic {
        .textpic-container {
            flex-direction: row;
        }
    }
}

.frame-grid-textmedia {
    h3 strong {
        font-weight: $text-media-font-weight;
        color: $text-media-heading-color;
    }
}

.frame-type-text {

    @include media-breakpoint-down(md) {
        .frame-grid {
            display: flex;
        }

        &.frame-layout-left {
            .frame-grid {
                flex-direction: row-reverse;
            }
        }

        .frame-icon {
            flex: 1 0 90px;
        }
    }

    .frame-icon {
        color: $text-icon-color;

        .svg-icon {
            width: calc(100% - 1rem);
            height: auto;
            margin-right: 1rem;
            margin-top: 0.375rem;
        }
    }
}

/* *************************************** * /
/*        Rocket Animation                 */
/* *************************************** */

@include media-breakpoint-up(md) {
    #svgRocket.animated{
        overflow: visible;

        .rocketAnimation{
            /*    animation-name:shake;animation-duration:100ms;animation-timing-function:ease-in-out;animation-iteration-count:20;*/
            animation: shake 100ms ease-in-out 6s 20, flyAway 8s ease-in 7.8s;
            animation-fill-mode: none;

            /* Delay= 4s delay for all parts of animation + individual delay */
        }

        .dustAnimation{
            animation: fadeIn 5s ease-in;
            animation-delay: calc(1s + 5s)
            /* Delay= 4s delay for all parts of animation + individual delay */
        }

        @media all and (-ms-high-contrast:none) {
            *::-ms-backdrop, .rocketAnimation, .dustAnimation {
                animation: none;
            } /* IE11 */
        }

        @supports (-ms-ime-align:auto) {
            .rocketAnimation,
            .dustAnimation{
                animation: none;
            }
        }
    }
}



@keyframes flyAway{
    35% {
        opacity: 0;
    }
    50% {
        transform: translateY(-3000px);
        -webkit-transform: translateY(-3000px);
        -ms-transform: translateY(-3000px);
        opacity: 0;
    }
    80% {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn{
    0% {
        opacity: 0;
    }
    36% {
        opacity: 0.3;
        transform:scale(1) translateX(0);
    }
    44% {
        opacity:1;
        transform:scale(1.2, 1.1)translateX(-8%);
    }
    100% {
        transform:scale(1);
    }
}

@keyframes shake {
    2% {
        transform: translate(-0.5px, 2.5px) rotate(-0.5deg); }
    4% {
        transform: translate(0.5px, -1.5px) rotate(-0.5deg); }
    6% {
        transform: translate(1.5px, -1.5px) rotate(1.5deg); }
    8% {
        transform: translate(0.5px, 2.5px) rotate(-0.5deg); }
    10% {
        transform: translate(-1.5px, 2.5px) rotate(1.5deg); }
    12% {
        transform: translate(2.5px, -0.5px) rotate(-0.5deg); }
    14% {
        transform: translate(2.5px, 2.5px) rotate(1.5deg); }
    16% {
        transform: translate(1.5px, 1.5px) rotate(0.5deg); }
    18% {
        transform: translate(0.5px, 2.5px) rotate(1.5deg); }
    20% {
        transform: translate(1.5px, -1.5px) rotate(-0.5deg); }
    22% {
        transform: translate(0.5px, 1.5px) rotate(1.5deg); }
    24% {
        transform: translate(-0.5px, 1.5px) rotate(0.5deg); }
    26% {
        transform: translate(-1.5px, -1.5px) rotate(0.5deg); }
    28% {
        transform: translate(1.5px, 2.5px) rotate(1.5deg); }
    30% {
        transform: translate(2.5px, -1.5px) rotate(1.5deg); }
    32% {
        transform: translate(1.5px, 1.5px) rotate(1.5deg); }
    34% {
        transform: translate(2.5px, 0.5px) rotate(-0.5deg); }
    36% {
        transform: translate(2.5px, -0.5px) rotate(-0.5deg); }
    38% {
        transform: translate(0.5px, 0.5px) rotate(0.5deg); }
    40% {
        transform: translate(1.5px, -1.5px) rotate(1.5deg); }
    42% {
        transform: translate(0.5px, -0.5px) rotate(0.5deg); }
    44% {
        transform: translate(-0.5px, -0.5px) rotate(-0.5deg); }
    46% {
        transform: translate(1.5px, 2.5px) rotate(-0.5deg); }
    48% {
        transform: translate(2.5px, 2.5px) rotate(1.5deg); }
    50% {
        transform: translate(0.5px, 2.5px) rotate(1.5deg); }
    52% {
        transform: translate(1.5px, -1.5px) rotate(1.5deg); }
    54% {
        transform: translate(0.5px, 0.5px) rotate(1.5deg); }
    56% {
        transform: translate(1.5px, 1.5px) rotate(0.5deg); }
    58% {
        transform: translate(0.5px, 0.5px) rotate(1.5deg); }
    60% {
        transform: translate(-0.5px, 0.5px) rotate(-0.5deg); }
    62% {
        transform: translate(2.5px, -1.5px) rotate(1.5deg); }
    64% {
        transform: translate(2.5px, -0.5px) rotate(0.5deg); }
    66% {
        transform: translate(0.5px, 0.5px) rotate(1.5deg); }
    68% {
        transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
    70% {
        transform: translate(2.5px, 0.5px) rotate(-0.5deg); }
    72% {
        transform: translate(1.5px, 0.5px) rotate(1.5deg); }
    74% {
        transform: translate(0.5px, 0.5px) rotate(1.5deg); }
    76% {
        transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
    78% {
        transform: translate(-1.5px, -1.5px) rotate(-0.5deg); }
    80% {
        transform: translate(-0.5px, -0.5px) rotate(0.5deg); }
    82% {
        transform: translate(-0.5px, 1.5px) rotate(1.5deg); }
    84% {
        transform: translate(-1.5px, 2.5px) rotate(-0.5deg); }
    86% {
        transform: translate(-1.5px, -0.5px) rotate(-0.5deg); }
    88% {
        transform: translate(2.5px, -0.5px) rotate(-0.5deg); }
    90% {
        transform: translate(2.5px, -1.5px) rotate(-0.5deg); }
    92% {
        transform: translate(2.5px, 0.5px) rotate(-0.5deg); }
    94% {
        transform: translate(0.5px, 1.5px) rotate(-0.5deg); }
    96% {
        transform: translate(1.5px, 2.5px) rotate(0.5deg); }
    98% {
        transform: translate(2.5px, -0.5px) rotate(1.5deg); }
    0%, 100% {
        transform: translate(0, 0) rotate(0);
    }
}

@keyframes shake-little {
    2% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    4% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    6% {
        transform: translate(1px, 1px) rotate(0.5deg); }
    8% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    10% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    12% {
        transform: translate(1px, 1px) rotate(0.5deg); }
    14% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    16% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    18% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    20% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    22% {
        transform: translate(1px, 1px) rotate(0.5deg); }
    24% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    26% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    28% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    30% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    32% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    34% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    36% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    38% {
        transform: translate(1px, 1px) rotate(0.5deg); }
    40% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    42% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    44% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    46% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    48% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    50% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    52% {
        transform: translate(1px, 1px) rotate(0.5deg); }
    54% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    56% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    58% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    60% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    62% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    64% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    66% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    68% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    70% {
        transform: translate(1px, 1px) rotate(0.5deg); }
    72% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    74% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    76% {
        transform: translate(0px, 1px) rotate(0.5deg); }
    78% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    80% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    82% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    84% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    86% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    88% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    90% {
        transform: translate(1px, 1px) rotate(0.5deg); }
    92% {
        transform: translate(1px, 1px) rotate(0.5deg); }
    94% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    96% {
        transform: translate(1px, 0px) rotate(0.5deg); }
    98% {
        transform: translate(0px, 0px) rotate(0.5deg); }
    0%, 100% {
        transform: translate(0, 0) rotate(0); }
}

.mpf-video-popup {
    max-width: 80%;
}
