/*Custom sytles for project*/
@import "variables";

/* cookieconsent styling */

.no-cookies {
    flex-direction: column;
    align-items: center;
}

.cc-window {
    position: fixed;
    overflow: hidden;
    opacity: 1;
    transition: opacity 1s ease;
    z-index: 99999;
    background-color: $primary-font-color;
    color: $primary-font-color;
    font-weight: 300;
    font-size: 20px;
    padding: 1rem;
    border: 1px solid $primary-font-color;

    /* Position */
    width: 100%;
    max-width: 440px;

    /* Top Center */
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.cc-hide {
    visibility: hidden !important;
    opacity: 0 !important
}

.cc-grower {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s;
}

.cc-revoke {
    display: none;
    visibility: hidden;
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
    cursor: pointer;
}

.cc-link {
    opacity: 0.8;
    display: inline-block;
    text-decoration: underline;

    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: 1px dotted;
    }

    &:active,
    &:visited {
        color: initial;
    }
}

.cc-btn {
    display: block;
    padding: 0.4rem 0.8rem;
    border-width: 1px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    color: $white;
    border-color: $primary-color;
    background-color: $primary-color;

    &:hover,
    &:focus {
        color: $white;
        background-color: darken($primary-color, 7%)!important;
        border-color: darken($primary-color, 7%);
        text-decoration: none;
    }

    &:focus {
        outline: 1px dotted;
    }
}

.cc-highlight {
    .cc-btn:not(:first-child) {
        background-color: transparent;
        border-color: transparent;
        text-decoration: underline;

        &:focus,
        &:hover {
            background-color: transparent!important;
            text-decoration: underline;
        }
    }

    .cc-btn {
        color: $white;
        border-color: $primary-color;
        background-color: $primary-color;

        &:hover,
        &:focus {
            color: $white;
            background-color: darken($primary-color, 7%)!important;
            border-color: darken($primary-color, 7%);
            text-decoration: none;
        }

        &:focus {
            outline: 1px dotted;
        }
    }
}


.cc-close {
    display: block;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    opacity: 0.9;
    line-height: 0.75;
}

.cc-close:focus,
.cc-close:hover {
    opacity: 1;
}

.cc-message {
    display: block;
    padding: 0 0.5rem;

    .cc-link {
        color: $white;
    }
}


.cc-compliance {
    display: flex;
    flex-wrap: wrap;

    .cc-btn {
        flex: 0 0 100%;
        max-width: 100%;

        & + .cc-btn {
            margin-top: 0.5rem;
        }
    }

}

@media print {
    .cc-revoke,
    .cc-window {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .cc-btn {
        white-space: normal;
    }
}

@media screen and (max-width: 419px) and (orientation: portrait),
screen and (max-width: 767px) and (orientation: landscape) {

    .cc-window{
        top: 0;
        left: 0;
        right: 0;
        bottom: unset;
        transform: translateX(0);
        max-width: none;
    }
}

.dp--cookie-check {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;

    label {
        padding: 5px;
        display: block;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 0 0 33.333%;
        max-width: 33.333%;
        font-size: 20px;
        font-weight: 300;
    }
}

.dp--iframe {
    display: none;

    &.dp--loaded {
        display: block;
    }
}

.dp--overlay {
    padding: 2.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    height:100%;
    width: 100%;
    background-color: $dark !important;

    .dp--overlay-description {
        font-size: 20px;
        font-weight: 300;
    }

    .dp--overlay-button {
        .db--overlay-submit {
            background: $primary-color !important;
            font-size: 20px;
        }
    }
}

.dp--overlay-header {
    font-size: 24px;
    font-weight: 400;
    font-family: Oswald,sans-serif;
}

.dp--overlay-description {
    margin-top: 0.8rem;
}

.dp--overlay-button {
    margin-top: 0.8rem;
}

.db--overlay-submit {
    border: 1px solid transparent;
    display: inline-block;
    padding: 0.4rem 0.8rem;
    text-align: center
}

.dp--iframe.dp--loaded+.dp--overlay {
    display: none
}

label[for=dp--cookie-thirdparty] {
    max-width: 100%;
    flex-grow: 3;
}
