@import "../../Contrib/MagnificPopup/main.scss";

/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.mfp-video-popup {
    position: relative;
    width: auto;
    max-width: 90%;
    margin: 20px auto;
}

.mfp-image {
    display: block;
}