@import "variables";
// Frame


.frame-header {
    position: relative;

    h2,
    .h2 {
        position: relative;
        color: $grey-900;
        display: inline-block;
        padding-bottom: 0.25rem;
        margin-bottom: 0.3rem;

        @include media-breakpoint-up(sm) {
            padding-bottom: 0.5rem;
            margin-bottom: 0.6rem;
        }

        @include media-breakpoint-up(sm) {
            padding-bottom: 0.5rem;
            margin-bottom: 0.6rem;
        }


        @include media-breakpoint-up(lg) {
            padding-bottom: 2rem;
            margin-bottom: 0;
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 60px;
            bottom: 0;
            border-top: 2px solid $primary-color;

            @include media-breakpoint-up(lg) {
                width: 100px;
            }
        }
    }
}


.frame-content {
    p:last-child {
        margin-bottom: 0;
    }
}

.frame-type-image,
.frame-grid,
.frame-grid-textmedia {
    margin-top: 3rem;
    margin-bottom: 3rem;

    &.frame-space-after-none {
        margin-bottom: 0;
    }

}

.frame-grid,
.frame-grid-textmedia {
    position: relative;
}

.frame:last-child {
    .frame-grid {
        margin-bottom: 0;
    }
}

.frame-arrow {
    position: absolute;
    bottom: -65px;
    width: 25px;
    z-index: 2;

    @include media-breakpoint-up(lg) {
        width: 46px;
        margin-left: 30px;
    }

    .svg-arrow {
        width: 100%;
        animation: bounce-animation 0.8s infinite alternate;
    }

    &.frame-arrow-left {
        left: 45px;

        @include media-breakpoint-up(lg) {
            left: 8.3333%;
        }
    }

    &.frame-arrow-right {
        right: 45px;
        transform: scaleX(-1);

        @include media-breakpoint-up(lg) {
            right: 8.3333%;
        }
    }
}

.frame-layout-left {
    .frame-header {
        text-align: right;
    }
}

@include media-breakpoint-down(md) {
    .frame-grid,
    .frame-grid-textmedia {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }


    .frame-grid {
        .frame-content,
        .frame-icon {
            margin-top: 1.5rem;
        }
    }

    .frame-type-textpic,
    .frame-type-list,
    .frame-type-wvier_process,
    .frame-type-wvier_statements{
        .frame-grid {
            .frame-content {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    .frame-type-text {
        .frame-grid {
            padding-left: 15px;
            padding-right: 15px;
        }
    }


    .frame-space-before-none {
        .frame-grid,
        .frame-grid-textmedia {
            padding-top: 0.25rem;
        }

        .frame-type-image,
        .frame-grid,
        .frame-grid-textmedia {
            margin-top: 0;
        }
    }

    .frame-space-after-none {
        .frame-grid,
        .frame-grid-textmedia {
            padding-bottom: 0.25rem;
        }

        .frame-type-image,
        .frame-grid,
        .frame-grid-textmedia {
            margin-bottom: 0;
        }
    }

    .frame-layout-right {
        .frame-grid,
        .frame-grid-textmedia {
            background: linear-gradient(to right, rgba($white, 0) 0, rgba($white, 0) 75px, rgba($light, 1) 75px, rgba($light, 1) 100%);
        }

        &.frame-type-textpic,
        &.frame-type-list,
        &.frame-type-wvier_process,
        &.frame-type-wvier_statements {
            .frame-grid {
                .frame-content {
                    margin-left: 90px;
                }
            }
        }

        .frame-header {
            h2,
            .h2 {
                &::after {
                    left: 45px;
                }
            }
        }
    }

    .frame-layout-left {

        .frame-grid,
        .frame-grid-textmedia {
            background: linear-gradient(to left, rgba($white, 0) 0, rgba($white, 0) 75px, rgba($light, 1) 75px, rgba($light, 1) 100%);
        }

        &.frame-type-textpic,
        &.frame-type-list,
        &.frame-type-wvier_process {
            .frame-grid {
                .frame-content {
                    margin-right: 90px;
                }
            }
        }

        &.frame-type-wvier_statements {
            .frame-grid {
                .frame-content {
                    margin-right: 75px;

                    @include media-breakpoint-up(md) {
                        margin-right: 90px;
                    }
                }
            }
        }

        .frame-header {
            h2,
            .h2 {
                &::after {
                    right: 45px;
                }
            }
        }
    }

    .frame-header {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 575.99px) {


    .frame-arrow {
        &.frame-arrow-left {
            left: 30px;
        }

        &.frame-arrow-right {
            right: 30px;
        }
    }


    .frame-layout-right {
        &.frame-type-list,
        &.frame-type-wvier_process {
            .frame-grid {
                .frame-content {
                    margin-left: 0;
                }
            }
        }

        &.frame-type-list .frame-grid {
            background: linear-gradient(to right, rgba($light, 1) 0%, rgba($light, 1) 100%);
        }
    }

    .frame-layout-left {
        &.frame-type-list,
        &.frame-type-wvier_process {
            .frame-grid {
                .frame-content {
                    margin-right: 0;
                }
            }
        }

        &.frame-type-list .frame-grid {
            background: linear-gradient(to right, rgba($light, 1) 0%, rgba($light, 1) 100%);
        }
    }

    .textpic-image {
        .img-round {
            max-width: 80px;
        }
    }
}

@include media-breakpoint-up(lg) {

    .frame-type-image,
    .frame-grid,
    .frame-grid-textmedia {
        margin-top: 6.75rem;
        margin-bottom: 6.75rem;
    }

    .frame-background {
        background-color: $light;
    }

    .frame-header {
        h4 {
            white-space: nowrap;
        }
    }

    .frame-grid,
    .frame-grid-textmedia {
        display: grid;
        grid-template-columns: 4.1667% 4.1667% 10.416667% 10.416667% 10.416667% 10.416667% 10.416667% 10.416667% 10.416667% 10.416667% 4.1667% 4.1667%;

        .frame-header {
            grid-row: 2 / span 1;
        }
    }

    .frame-grid {
        grid-template-rows: 3.75rem auto auto 3.75rem;

        .frame-background {
            grid-row: 1 / span 4;
        }

        .frame-content {
            grid-row: 2 / span 2;
            margin-top: 2.125rem;
        }

        .frame-icon {
            grid-row: 2 / span 2;
            margin-top: 2.125rem;
        }
    }

    .frame-grid-textmedia {
        grid-template-rows: 3.75rem auto auto 3.5rem auto auto 3.75rem;

        .frame-background {
            grid-row: 1 / span 7;
        }

        .frame-image {
            grid-row: 3 / span 2;
        }

        .frame-content {
            grid-row: 5 / span 1;
        }

        .frame-lightbox {
            grid-row: 4 / span 1;
        }

        .frame-name {
            grid-row: 2 / span 1;
            padding-bottom: 2rem;
            margin-left: 10rem;
            margin-top: 2.5625rem;

            .insert-name {
                word-break: break-word;
            }
        }
    }

    .frame-space-before-none{
        .frame-grid {
            margin-top: 0;
            grid-template-rows: 0.25rem auto auto 3.75rem;
        }
    }

    .frame-space-after-none{
        .frame-grid {
            margin-bottom: 0;
            grid-template-rows: 3.75rem auto auto 0.25rem;
        }
    }

    .frame-space-before-none.frame-space-after-none{
        .frame-grid {
            margin-top: 0;
            margin-bottom: 0;
            grid-template-rows: 0.25rem auto auto 0.25rem;
        }
    }

    .frame-layout-right {

        .frame-grid,
        .frame-grid-textmedia {
            .frame-header {
                grid-column: 3 / span 3;
                padding-right: 0.25rem;

                h2,
                .h2 {
                    &::after {
                        left: 3.75rem;
                    }
                }

            }

            .frame-background {
                grid-column: 4 / span 9;
            }
        }

        .frame-grid {
            .frame-content {
                grid-column: 5 / span 7;
            }

            .frame-icon {
                grid-column: 5 / span 1;
            }
        }

        .frame-grid-textmedia {
            .frame-image {
                grid-column: 5 / span 7;
            }

            .frame-content {
                grid-column: 3 / span 6;
            }

            .frame-lightbox {
                grid-column: 8 / span 4;
            }

            .frame-name {
                grid-column: 5 / span 7;
            }
        }


        &.frame-type-wvier_benefits {
            .frame-grid {
                .frame-content {
                    grid-column: 5 / span 6;
                }
            }
        }

        &.frame-type-text,
        &.frame-type-textpic,
        &.frame-type-list {
            .frame-grid {
                .frame-content {
                    grid-column: 6 / span 5;
                }
            }
        }
    }

    .frame-layout-left {

        .frame-grid,
        .frame-grid-textmedia {
            .frame-header {
                grid-column: 7 / span 4;
                padding-left: 0.25rem;

                h2,
                .h2 {
                    &::after {
                        right: 3.75rem;
                    }
                }

            }

            .frame-background {
                grid-column: 1 / span 9;
            }
        }

        .frame-grid {

            .frame-content {
                grid-column: 2 / span 7;
            }

            .frame-icon {
                grid-column: 2 / span 1;
            }
        }

        .frame-grid-textmedia {

            .frame-image {
                grid-column: 2 / span 7;
            }

            .frame-content {
                grid-column: 5 / span 6;
            }

            .frame-lightbox {
                grid-column: 2 / span 4;
            }

            .frame-name {
                grid-column: 2 / span 6;
            }
        }

        &.frame-type-wvier_benefits {
            .frame-grid {
                .frame-content {
                    grid-column: 3 / span 6;
                }
            }
        }

        &.frame-type-text,
        &.frame-type-textpic,
        &.frame-type-list {
            .frame-grid {
                .frame-content {
                    grid-column: 3 / span 5;
                }
            }
        }
    }
}

@media (min-width: 1440px) {

    .frame-layout-right {
        .frame-grid,
        .frame-grid-textmedia {
            .frame-header {
                h2,
                .h2 {
                    &::after {
                        left: 7.25rem;
                    }
                }

            }
        }
    }

    .frame-layout-left {
        .frame-grid,
        .frame-grid-textmedia {
            .frame-header {
                h2,
                .h2 {
                    &::after {
                        right: 7.25rem;
                    }
                }

            }
        }
    }


}

@media (min-width: 1860px) {
    .frame-grid,
    .frame-grid-textmedia {
        grid-template-columns: 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333%;
    }

    .frame-arrow {
        &.frame-arrow-left {
            left: 16.66667%
        }

        &.frame-arrow-right {
            right: 16.66667%
        }
    }
}
